import React from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faRuble from '@fortawesome/fontawesome-free-solid/faRubleSign';

import TextFormatting from '../../utils/textFormatting';

function AbonementCost(props) {
  const { cost = 0 } = props;

  function formattingCost() {
    const [integerPart, fractionalPart] =  cost.toString().split('.');
    const resInt = TextFormatting.formatingNumber(parseInt(integerPart,10), ' ', 3);
    const resFractional = fractionalPart ? fractionalPart.padEnd(2,'0') : '00';
    return `${resInt}.${resFractional}`;
  }

  return (
    <div className="abonement__cost">
      {`${formattingCost()}`}
      <FontAwesomeIcon className="abonement__ruble-sign" icon={faRuble} />
    </div>
  );
}

export default AbonementCost;
