import React, { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import { Col, Row } from "reactstrap";

const ReceiptLegal = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [address, setAddress] = useState('');
  const [typeDoc, setTypeDoc] = useState('');
  const [seriesDoc, setSeriesDoc] = useState('');
  const [numberDoc, setNumberDoc] = useState('');
  const [dateDoc, setDateDoc] = useState('');
  const [issuer, setIssuer] = useState('');
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [middleNameDirty, setMiddleNameDirty] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);
  const [seriesDocDirty, setSeriesDocDirty] = useState(false);
  const [numberDocDirty, setNumberDocDirty] = useState(false);
  const [dateDocDirty, setDateDocDirty] = useState(false);
  const [issuerDirty, setIssuerDirty] = useState(false);
  const [firstNameError, setFirstNameError] = useState('Необходимо заполнить');
  const [lastNameError, setLastNameError] = useState('Необходимо заполнить');
  const [middleNameError, setMiddleNameError] = useState('Необходимо заполнить');
  const [addressError, setAddressError] = useState('Необходимо заполнить');
  const [seriesDocError, setSeriesDocError] = useState('Необходимо заполнить');
  const [numberDocError, setNumberDocError] = useState('Необходимо заполнить');
  const [dateDocError, setDateDocError] = useState('Необходимо заполнить');
  const [issuerError, setIssuerError] = useState('Необходимо заполнить');
  const [formValid, setFormValid] = useState(false);

  useEffect(
    () => {
      if (firstNameError || lastNameError || middleNameError || addressError || seriesDocError || numberDocError
        || dateDocError || issuerError
      ) {
        setFormValid(false);
      } else {
        setFormValid(true);
      }
    }, [
      firstNameError,
      lastNameError,
      middleNameError,
      addressError,
      seriesDocError,
      numberDocError,
      dateDocError,
      issuerError,
    ]
  );

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
    if(!e.target.value.length) {
      setFirstNameError('Необходимо заполнить');
    } else {
      setFirstNameError('');
    }
  }

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
    if(!e.target.value.length) {
      setLastNameError('Необходимо заполнить');
    } else {
      setLastNameError('');
    }
  }

  const middleNameHandler = (e) => {
    setMiddleName(e.target.value);
    if(!e.target.value.length) {
      setMiddleNameError('Необходимо заполнить');
    } else {
      setMiddleNameError('');
    }
  }

  const addressHandler = (e) => {
    setAddress(e.target.value);
    if(!e.target.value.length) {
      setAddressError('Необходимо заполнить');
    } else {
      setAddressError('');
    }
  }

  const typeDocHandler = (e) => {
    setTypeDoc(e.target.value)
  }

  const seriesDocHandler = (e) => {
    setSeriesDoc(e.target.value);
    if(!e.target.value.length) {
      setSeriesDocError('Необходимо заполнить');
    } else {
      const re  = /^\d+$/
      if (!re.test(String(e.target.value))) {
        setSeriesDocError('Пожалуйста, укажите только цифры');
      } else {
        setSeriesDocError('');
      }
    }
  }

  const numberDocHandler = (e) => {
    setNumberDoc(e.target.value);
    if(!e.target.value.length) {
      setNumberDocError('Необходимо заполнить');
    } else {
      const re  = /^\d+$/
      if (!re.test(String(e.target.value))) {
        setNumberDocError('Пожалуйста, укажите только цифры');
      } else {
        setNumberDocError('');
      }
    }
  }

  const dateDocHandler = (e) => {
    setDateDoc(e.target.value)
    if(!e.target.value.length) {
      setDateDocError('Необходимо заполнить');
    } else {
      const re = /^(19\d\d|20\d\d)-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/
      if (!re.test(String(e.target.value))) {
        setDateDocError('Дата не корректна')
      } else {
        setDateDocError("");
      }
    }
  }

  const issuerHandler = (e) => {
    setIssuer(e.target.value);
    if(!e.target.value.length) {
      setIssuerError('Необходимо заполнить');
    } else {
      setIssuerError('');
    }
  }

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstNameDirty(true);
        break;
      case 'lastName':
        setLastNameDirty(true);
        break;
      case 'middleName':
        setMiddleNameDirty(true);
        break;
      case 'address':
        setAddressDirty(true);
        break;
      case 'seriesDoc':
        setSeriesDocDirty(true);
        break;
      case 'numberDoc':
        setNumberDocDirty(true);
        break;
      case 'DateDoc':
        setDateDocDirty(true);
        break;
      case 'issuer':
        setIssuerDirty(true);
        break;
    }
  }

  return (
    <>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="lastName">ФАМИЛИЯ:
              { (lastNameDirty && lastNameError) && <span className="pl-2" style={{color:'red'}}>{lastNameError}</span> }
            </label>
            <input
              defaultValue={lastName}
              onChange={e => lastNameHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="lastName"
              type="text"
              name="lastName"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="firstName">ИМЯ:
              { (firstNameDirty && firstNameError) && <span className="pl-2" style={{color:'red'}}>{firstNameError}</span> }
            </label>
            <input
              defaultValue={firstName}
              onChange={e => firstNameHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="firstName"
              type="text"
              name="firstName"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="middleName">ОТЧЕСТВО:
              { (middleNameDirty && middleNameError) && <span className="pl-2" style={{color:'red'}}>{middleNameError}</span> }
            </label>
            <input
              defaultValue={middleName}
              onChange={e => middleNameHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="middleName"
              type="text"
              name="middleName"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="address">АДРЕС:
              { (addressDirty && addressError) && <span className="pl-2" style={{color:'red'}}>{addressError}</span> }
            </label>
            <input
              defaultValue={address}
              onChange={e => addressHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="address"
              type="text"
              name="address"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <p>Удостоверение личности</p>
            <label className="w-100 form-label" htmlFor="typeDoc">ТИП:
            </label>
            <select
              name="typeDoc"
              id="typeDoc"
              value={typeDoc}
              className={"w-100 form-control"}
              onChange={e => typeDocHandler(e)}
            >
              <option value="1">Паспорт</option>
              <option value="2">Водительские права</option>
            </select>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="seriesDoc">СЕРИЯ:
              { (seriesDocDirty && seriesDocError) && <span className="pl-2" style={{color:'red'}}>{seriesDocError}</span> }
            </label>
            <input
              defaultValue={seriesDoc}
              onChange={e => seriesDocHandler(e)}
              onBlur={e => blurHandler(e)}
              className="form-control"
              id="seriesDoc"
              name="seriesDoc"
              type="text"
            />
          </Col>
          <Col>
            <label className="w-100 form-label" htmlFor="numberDoc">НОМЕР:
              { (numberDocDirty && numberDocError) && <span className="pl-2" style={{color:'red'}}>{numberDocError}</span> }
            </label>
            <input
              defaultValue={numberDoc}
              onChange={e => numberDocHandler(e)}
              onBlur={e => blurHandler(e)}
              className="form-control"
              id="numberDoc"
              name="numberDoc"
              type="text"
            />
          </Col>
          <Col>
            <label className="w-100 form-label" htmlFor="dateDoc">ДАТА ВЫДАЧИ:
              { (dateDocDirty && dateDocError) && <span className="pl-2" style={{color:'red'}}>{dateDocError}</span> }
            </label>
            <input
              defaultValue={dateDoc}
              onChange={e => dateDocHandler(e)}
              onBlur={e => blurHandler(e)}
              className="form-control"
              id="dateDoc"
              name="DateDoc"
              type="date"
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="issuer">КЕМ ВЫДАН:
              { (issuerDirty && issuerError) && <span className="pl-2" style={{color:'red'}}>{issuerError}</span> }
            </label>
            <input
              defaultValue={issuer}
              onChange={e => issuerHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="issuer"
              type="text"
              name="issuer"/>
          </Col>
        </Row>
      </FormGroup>
      <input type="hidden" value="1" name="type"/>
      <input type="hidden" value={props.amount} name="sum"/>
      <input type="hidden" value={props.codeSubscription} name="codeSubscription"/>
      <input type="hidden" value={props.grz} name="grz"/>
      <FormGroup>
        <Row>
          <Col className={"text-center"}>
            <Button
              disabled={!formValid}
              color="primary"
              type="submit"
              className="submit"
              style={{ appearance: 'none' }}
            >
              Далее
            </Button>
          </Col>
          <Col className={"text-center"}>
            <Button
              outline
              color="primary"
              onClick={props.onAbort}
              className="cancel"
            >
              {props.t('operations:annulment')}
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </>
  );
}

export default ReceiptLegal;